import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Food } from 'components/Food';
import { FoodList } from 'components/FoodList';
import { PlaceHero } from 'components/PlaceHero';
import SEO from 'components/SEO';
import Layout from 'components/layout';
import {
  PHAN_BAOS,
  PHAN_DIPS,
  PHAN_DISH,
  PHAN_MAIN,
  PHAN_MENU,
  PHAN_SIDES,
  PHAN_SOUP,
} from '../data/food';

const MrPhanPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "venue-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "venue-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      food: allFile(filter: { name: { regex: "/(phan-)/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout>
      <SEO title="Mr. Phan House" />
      <div className="max-w-screen-xl px-6 sm:mx-auto py-4 md:py-8">
        <PlaceHero
          title="Mr. Phan House"
          phone="26563581"
          address={[
            {
              street: 'Lerchesgade 4',
              city: '5000 Odense C',
              map: 'https://www.google.com/maps/place/Storms+Pakhus+-+Odense+Street+Food/@55.404427,10.3825698,15z/data=!4m2!3m1!1s0x0:0xf9fb82e3bb6025d3?sa=X&ved=2ahUKEwiFl_GS0q7pAhUnxcQBHeMtA10Q_BIwCnoECBAQCg',
            },
            {
              street: 'Nicoline Kochs Plads Pl 4',
              city: '8000 Aarhus C',
              map: 'https://maps.app.goo.gl/CS48qxy1957guqxX6',
            },
          ]}
          social={{
            facebook: 'https://www.facebook.com/Mrphanhouse5000',
            instagram: 'https://www.instagram.com/mrphanhouse/',
          }}
          sources={sources}
          openingHours={[
            {
              title: 'Søndag - Torsdag',
              time: '11:00-21:00',
            },
            {
              title: 'Fredag - Lørdag',
              time: '11:00-22:00',
            },
          ]}
        />

        {/* <InfoBox phone="26563581" /> */}

        {/* <FoodList title="Hovedretter">
          {PHAN_MAIN.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList> */}

        <FoodList title="Bao">
          {PHAN_BAOS.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Sides">
          {PHAN_SIDES.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>

        <FoodList title="Retter">
          {PHAN_DISH.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
        <FoodList title="Suppe">
          {PHAN_SOUP.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
        <FoodList
          title="Menu"
          description="Vælg mellem char siu marineret svine kød, sprød kylling, stegt kylling eller hoisin marineret pull beef."
        >
          {PHAN_MENU.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
        <FoodList title="Dips">
          {PHAN_DIPS.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
      </div>
    </Layout>
  );
};

export default MrPhanPage;
